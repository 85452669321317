/* The container for ALL of the project cards, together */
.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: black;
}
#prevwork-section {
  padding: 20px 0;
  text-align: center;
}