/* The container for EACH project card */
.project-card {
  max-width: 400px;
  min-height: 500px; 
  margin: 2% 2%;
  display: inline-flex;
  flex-flow: column;  
  vertical-align:top;
  background-color: #FFF;
  box-shadow: 0 0 10px 0 #777;
  border-radius: .6em;
}

.short {
  min-height: 0;
}

.wide {
  max-width: 600px;
}

.card-img {
  flex: 0 1 auto;
  width: 92%;
  margin: 4%;
  border-radius: .6em;
}

.card-content {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  text-align: center;
  font-size: 1em;
  padding: 0 5%;
}

.card-header-div {
  margin-bottom: 4%;
  text-align: left;
}

.card-header-div h6 {
  margin: 0;
  font-weight: 300;
}

.card-header-titlelink {
  display: flex;
  justify-content: space-between;
}

.card-text {
  flex: 1 1 auto;
  color: #666!important;
  text-align: left;
}

/*
Language tags
 */
.language-tags {
  flex: 0 1 auto;
  margin: 5% 0;
  text-align: center;
}

.language-tag {
  display: inline-block;
  font-size: 0.9em;
  font-weight: 400;
  padding: 1% 1%;
  margin: 1% 0.75%;
  border-radius: 0.4em;
  border: 2px #87CEFA solid;
}

.language-tag:hover {
  cursor: default;
  color: white;
  background-color: #87CEFA;
}


/*
Link
 */
.project-link {
  margin: 2% 1%;
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

.project-link:hover {
  color: #87CEFA;
  text-decoration: none;
}


/*
Mobile
 */
@media only screen and (max-width: 600px) {
  .project-card {
    width: 90%;
  }
  .projects-container {
    padding: 0;
  }
}