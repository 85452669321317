
#contact-section {
  background-color: #444;
}
#contact-social {
  margin: 0 auto;
  width: 50%;
  text-align: center;
}
#contact-social img {
  margin: 0 10px;
  width: 50px;
  height: 50px;
}