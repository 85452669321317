.flag-top, .flag-bottom {
  height: 60px;
}

.flag-top {
  background-color: #005bbb;
}

.flag-bottom {
  background-color: #fed500;
}


.ukraine-text {
  margin: 0 10%;
  padding: 5% 0;
  border-bottom: black solid 1px;
}

.ukraine-text ul {
  padding-left: 30px;
}

.ukraine-text h2 {
  text-align: center;
  font-weight: 800;
}

.terminal-section {
  height: 80%;
  padding: 5% 5%;
}
