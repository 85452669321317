nav {
  width: 100%;
  background: #222;
  position: fixed;
  z-index: 100;
}
nav ul {
  margin-bottom: 0 !important;
  list-style: none;
  text-align: center;
}
nav ul li {
  display: inline-block;
  padding: 0 15px 5px;
}
nav a {
  display: block;
  padding: 10px;
  text-decoration: none !important;
  font-size: 1.2em;
  position: relative;
  color: #FFF;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 10px;
}
nav a:hover, nav li.active a  {
  color: #87CEFA;
}
nav a:after, nav li.active a::after {
  transition: all .3s;
  position: absolute;
  bottom: .5vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '';
  color: transparent;
  background: #87CEFA;
  height: 3px;
}
nav a:hover:after, nav li.active a::after {
  width: 100%;
}
#navbaricon {
  display: none;
  /* Original icon is black - invert the color */
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

a.anchor {
  display: inline-block;
  padding-top: 50px;
  margin-top: -50px;
}

#profilepic {
  padding-left: 40%;
}

.parallax {
  padding-top: 12vh;
}

/* Small Screen */
@media screen and (max-width: 600px) {
  nav a {
    display: none;
  }
  #first {
    display: block;
  }
  #navbaricon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  nav.responsive a {
    display: block;
  }
  nav ul li {
    display: block;
    padding: 0;
  }
  #profilepic {
    padding: 5% 0;
    width: 100%;
  }
  .profile-container {
    padding-top: 5%;
  }
}