.container {
  width: 70%;
  background-color: #eef7ff;
  padding: 2% 5%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#diagram {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    margin: 0;
  }
}

textarea {
  width: 300px;
  height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}