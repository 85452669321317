.profile-container {
  text-align: center;
}

.profile-container h1 {
  font-size: 3em !important;
  padding: 0 15px;
  border-radius: 15px;
  display:inline-block; 
}

.profile-photo-container {
  padding-top: 5vh;
  height: 30vh;
  width: 30vh;
  overflow: hidden;
  margin: auto;
}

.profile-photo-img {
  display: inline;
  margin: 0 auto;
  height: 90%;
  width: auto;
  border-radius: 50%;
}
